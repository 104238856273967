import styled from 'styled-components'
import { Box } from 'rebass'
import theme from '../../fdg.theme'

const Body = styled(Box)`
  position: relative;
  z-index: 1;
  padding: ${theme.space[3]}px;
`

export default Body
