import './fonts.css'
import 'typeface-didact-gothic'
import 'typeface-life-savers'
import 'typeface-pompiere'
import 'typeface-vollkorn'

export default {
  breakpoints: ['320px', '375px', '414px', '768px', '1024px', '1200px'],
  fontSizes: [
    13, 18, 22, 28, 36, 44,
  ],
  colors: {
    transparent: 'transparent',

    black: '#22292f',
    greyDarkest: '#3d4852',
    greyDarker: '#606f7b',
    greyDark: '#8795a1',
    grey: '#b8c2cc',
    greyLight: '#dae1e7',
    greyLighter: '#f1f5f8',
    greyLightest: '#f8fafc',
    white: '#ffffff',

    redDarkest: '#3b0d0c',
    redDarker: '#621b18',
    redDark: '#cc1f1a',
    red: '#e3342f',
    redLight: '#ef5753',
    redLighter: '#f9acaa',
    redLightest: '#fcebea',

    orangeDarkest: '#462a16',
    orangeDarker: '#613b1f',
    orangeDark: '#de751f',
    orange: '#f6993f',
    orangeLight: '#faad63',
    orangeLighter: '#fcd9b6',
    orangeLightest: '#fff5eb',

    yellowDarkest: '#453411',
    yellowDarker: '#684f1d',
    yellowDark: '#f2d024',
    yellow: '#ffed4a',
    yellowLight: '#fff382',
    yellowLighter: '#fff9c2',
    yellowLightest: '#fcfbeb',

    greenDarkest: '#0f2f21',
    greenDarker: '#1a4731',
    greenDark: '#1f9d55',
    green: '#38c172',
    greenLight: '#51d88a',
    greenLighter: '#a2f5bf',
    greenLightest: '#e3fcec',

    tealDarkest: '#0d3331',
    tealDarker: '#20504f',
    tealDark: '#38a89d',
    teal: '#4dc0b5',
    tealLight: '#64d5ca',
    tealLighter: '#a0f0ed',
    tealLightest: '#e8fffe',

    blueDarkest: '#12283a',
    blueDarker: '#1c3d5a',
    blueDark: '#2779bd',
    blue: '#3490dc',
    blueLight: '#6cb2eb',
    blueLighter: '#bcdefa',
    blueLightest: '#eff8ff',

    indigoDarkest: '#191e38',
    indigoDarker: '#2f365f',
    indigoDark: '#5661b3',
    indigo: '#6574cd',
    indigoLight: '#7886d7',
    indigoLighter: '#b2b7ff',
    indigoLightest: '#e6e8ff',

    purpleDarkest: '#21183c',
    purpleDarker: '#382b5f',
    purpleDark: '#794acf',
    purple: '#9561e2',
    purpleLight: '#a779e9',
    purpleLighter: '#d6bbfc',
    purpleLightest: '#f3ebff',

    pinkDarkest: '#451225',
    pinkDarker: '#6f213f',
    pinkDark: '#eb5286',
    pink: '#f66d9b',
    pinkLight: '#fa7ea8',
    pinkLighter: '#ffbbca',
    pinkLightest: '#ffebef',

    fdgBeige: '#f6dcb3',
    fdgRed: '#dc322f',
    fdgBlack: '#2b2d42',
    fdgBlack50: 'rgba(43, 45, 46, 0.5)',
    fdgGrey: '#ccc',
    fdgLightGray: '#fafafa',
  },
  space: [
    0, 4, 8, 16, 32, 64, 128, 256,
  ],
  fonts: {
    sans: 'system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
    headline: '"Life Savers", cursive',
    subtitle: '"Didact Gothic", sans-serif',
    body: 'Vollkorn, serif',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  lineHeights: {
    none: 1,
    tight: 1.25,
    normal: 1.5,
    loose: 2,
    l_1: '20px',
    l0: '28px',
    l1: '32px',
    l2: '44px',
    l3: '56px',
    l4: '66px',
  },
  shadows: {
    small: '0 15px 60px -15px rgba(0,0,0,.5)',
    large: '0 64px 64px 0,' +
      '0 32px 32px 0,' +
      '0 16px 16px 0,' +
      '0 8px 8px 0,' +
      '0 4px 4px 0',
  },
}
