import * as React        from 'react'
import 'normalize.css'
import { ThemeProvider } from 'styled-components'
import { Box, Flex }     from 'rebass'
import theme             from '../fdg.theme'
import { Container }     from '../components/container'

export const DefaultLayout = ({ children, ...props }) =>
  <ThemeProvider theme={theme}>

    <Flex
      flexDirection="column"
      css={{
        height: '100vh',
      }}
    >
      <Box
        bg={'greyLightest'}
        css={{
          flexGrow: 1,
        }}
        {...props}
      >
        <Container
          px={[3, 3, 4]}
          pb={4}
          css={{
            background: 'inherit',
          }}
        >
          {children}
        </Container>
      </Box>
    </Flex>

  </ThemeProvider>
