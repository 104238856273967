module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"L'Étrange Fabrique de Gigi","short_name":"L'Étrange Fabrique de Gigi","start_url":"/","background_color":"rgba(0, 0, 0, 0)","theme_color":"#F6E8AB","display":"minimal-ui","icon":"static/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"11680fe7b35b2d1b2015b541e61d959d"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"injectPageProps":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"debug":false,"googleAnalytics":{"trackingId":"UA-165296590-1","autoStart":false,"anonymize":true,"controlCookieName":"gdpr-ga"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
