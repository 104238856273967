import styled from 'styled-components'
import { Text } from 'rebass'
import theme from '../../fdg.theme'

const StyledText = styled(Text)`
  line-height: ${theme.lineHeights.tight};
  font-family: ${theme.fonts.body};
  font-size: ${theme.fontSizes[0]}px;
  color: ${theme.colors.greyDarkest};
`

export default StyledText
