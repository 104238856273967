import React from 'react'
import { Box, Flex, Text } from 'rebass'
import Logo from './logo'
import './nav.css'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import styled from 'styled-components'

const anchorStyles = {
  position: 'relative',

  ':before,:after': {
    content: '""',

    height: '2px',

    position: 'absolute',
    bottom: '-6px',
    left: '50%',

    marginLeft: '-50%',

    backgroundColor: '#2f365f',
    borderRadius: '2px',

    visibility: 'hidden',
    transform: 'scaleX(0)',
    transition: 'all 0.2s ease-in-out 0s',
  },

  ':before': {
    width: '100%',
  },

  ':after': {
    width: '12px',
    marginLeft: '-6px',
  },

  ':hover:before': {
    visibility: 'visible',
    transform: 'scaleX(0.5)',
  },

  '&.current:not(:hover)': {
    color: '#2f365f',

    ':after': {
      visibility: 'visible',
      transform: 'scaleX(1)',
    },
  },
}

const Link = styled(Text)`
  color: ${({ theme }) => theme.colors.indigoDarker};
  font-family: ${({ theme }) => theme.fonts.sans};
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  text-transform: uppercase;
  letter-spacing: 1px;
`

export const Header = () => {
  return (
    <Flex
      className="header"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={[3, 3, 3, 4]}
      mb={[2, 2, 3, 4]}
    >
      <Flex
        alignItems="flex-end"
        justifyContent="space-between"
        mx={[-2, -2, -3]}
      >
        <Box
          px={[1, 1, 1, 2, 3]}
          mb={[24, 24, 32, 32]}
          css={{
            minWidth: 116,
            textAlign: 'center',
          }}
        >
          <AniLink
            paintDrip
            hex="#2b2d42"
            to="/"
            activeClassName="current"
            css={anchorStyles}
          >
            <Link as="span">Projets</Link>
          </AniLink>
        </Box>

        <Box px={[1, 1, 1, 2, 3]}>
          <AniLink paintDrip hex="#2b2d42" to="/">
            <Logo />
          </AniLink>
        </Box>

        <Box
          px={[1, 1, 1, 2, 3]}
          mb={[24, 24, 32, 32]}
          css={{
            minWidth: 116,
            textAlign: 'center',
          }}
        >
          <AniLink
            paintDrip
            duration={1}
            hex="#2b2d42"
            to="/a-propos"
            activeClassName="current"
            css={anchorStyles}
          >
            <Link as="span">À propos</Link>
          </AniLink>
        </Box>
      </Flex>
    </Flex>
  )
}
