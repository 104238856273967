import styled from 'styled-components'
import { Heading } from 'rebass'
import theme from '../../fdg.theme'

const Title = styled(Heading)`
  color: ${theme.colors.indigoDark};
  font-family: ${theme.fonts.headline};
  margin-bottom: ${theme.space[2]}px;
`

export default Title
