import React, { useEffect, useState } from 'react'
import useCookiesConsent from '../../hooks/cookies-consent.hook'
import Modal from '../modal'
import theme from '../../fdg.theme'
import Container from './container.styled'
import Body from './body.styled'
import Title from './title.styled'
import Text from './text.styled'
import Footer from './footer.styled'
import Button from './button.styled'

const View = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [state, accept, decline] = useCookiesConsent('gdpr-ga')

  const close = () => setIsVisible(false)

  useEffect(() => {
    if (!state.isTouched) {
      // Delay modal appears.
      setTimeout(() => {
        setIsVisible(true)
      }, 2000)
    }
  }, [state])

  const handleAccept = () => {
    accept()
    close()
  }

  const handleDecline = () => {
    decline()
    close()
  }

  return (
    <Modal isOpen={isVisible}>
      <Container width={['90%', '90%', '90%', 300]}>
        <Body zIndex={1}>
          <Title as="h6">C'est nous les Cookies !</Title>

          <Text as="p">
            Nous utilisons <strong>Google Analytics</strong> pour mesurer notre
            audience et améliorer l'expérience utilisateur.
          </Text>
        </Body>

        <Footer>
          <Button color={theme.colors.greyDark} onClick={handleDecline}>
            Non merci
          </Button>
          <Button
            color={theme.colors.indigoDark}
            onClick={handleAccept}
          >
            Ça me va
          </Button>
        </Footer>
      </Container>
    </Modal>
  )
}

export default View
