import styled from 'styled-components'
import { Box } from 'rebass'
import theme from '../../fdg.theme'

const Container = styled(Box)`
  position: fixed;
  left: 5%;

  @media screen and (min-width: 768px) {
    left: 32px;
  }

  bottom: 32px;
  z-index: 2;

  border: solid 1px hsla(0, 0%, 20%, 0.1);
  background-color: ${theme.colors.greyLightest};

  box-shadow: ${theme.shadows.small};

  :after {
    content: '';
    opacity: 0.6;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;

    box-shadow: inset 100px 40px 100px rgba(99, 201, 223, 0.6),
      inset -100px -60px 100px rgba(223, 194, 99, 0.6);
  }

  &,
  :after {
    border-radius: 4px;
  }
`

export default Container
