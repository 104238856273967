import styled from 'styled-components'
import { Button } from 'rebass'
import theme from '../../fdg.theme'

const StyledButton = styled(Button)`
  font-family: ${theme.fonts.sans};
  font-weight: normal;
  font-size: ${theme.fontSizes[0]}px;
  background-color: transparent;

  cursor: pointer;
`

export default StyledButton
