const React = require('react')
const { ThemeProvider }  = require('styled-components')
const theme = require('./src/fdg.theme')
const { DefaultLayout } = require('./src/layouts/default-layout')
const { Header } = require('./src/components/header')
const CookiesConsent = require('./src/components/cookies-consent').default
require('./src/global.css')

exports.wrapPageElement = ({element}) => (
  <DefaultLayout>
    <Header />{element}
  </DefaultLayout>
)

exports.wrapRootElement = ({element}) => (
  <ThemeProvider theme={theme}>
    {element}
    <CookiesConsent />
  </ThemeProvider>
)
