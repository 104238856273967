import React from 'react'
import { Box } from 'rebass'

export const Container = props => (
  <Box
    {...props}
    mx={'auto'}
    css={{
      maxWidth: '1000px',
    }}
  />
)
