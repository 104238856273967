import React from 'react'
import { Box } from 'rebass'
import InlineLogo from '../../static/logo.inline.svg'

const Logo = () => (
  <Box
    width={[90, 110, 120, 130, 180]}
    css={{
      userSelect: 'none',
    }}
    aria-label="L'Étrange Fabrique de Gigi"
  >
    <InlineLogo alt="L'Étrange Fabrique de Gigi" />
  </Box>
)

export default React.memo(Logo)
