import ReactDOM from 'react-dom'
import { useEffect } from 'react'

const portalRoot =
  typeof document !== 'undefined' ? document.getElementById('portal') : null

const View = ({ isOpen, children }) => {
  const el =
    typeof document !== 'undefined' ? document.createElement('div') : null

  useEffect(() => {
    portalRoot.appendChild(el)

    return () => {
      portalRoot.removeChild(el)
    }
  }, [el])

  if (isOpen && el) {
    return ReactDOM.createPortal(children, el)
  } else {
    return null
  }
}

export default View
