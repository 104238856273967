import Cookies from 'js-cookie'
import { useReducer } from 'react'

const RESET = 'init'
const ACCEPT = 'accept'
const DECLINE = 'decline'

const initialState = {
  isTouched: false,
  isConsent: false,
}

const resetState = {
  isTouched: false,
  isConsent: false,
}

const acceptState = {
  isTouched: true,
  isConsent: true,
}

const declineState = {
  isTouched: true,
  isConsent: false,
}

const init = isConsent => {
  // User has never chosen: we reset state.
  if (isConsent === undefined) {
    return resetState
  } else if (isConsent) {
    return acceptState
  } else {
    return declineState
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case RESET:
      return initialState

    case ACCEPT:
      return acceptState

    case DECLINE:
      return declineState

    default:
      throw new Error('ACCEPT and DECLINE are the only available actions.')
  }
}

const useCookiesConsent = cookie => {
  const isConsent = Cookies.get(cookie)
  const [state, dispatch] = useReducer(reducer, isConsent, init)

  const accept = () => {
    Cookies.set(cookie, true)

    // Enable Google Analytics if available.
    // Must be called after setting the consent cookie.
    // eslint-disable-next-line no-undef
    if (window && !!window.trackGoogleAnalytics) {
      window.trackGoogleAnalytics()
    }
    dispatch({ type: ACCEPT })
  }

  const decline = () => {
    Cookies.set(cookie, false)
    dispatch({ type: DECLINE })
  }

  return [state, accept, decline]
}

export default useCookiesConsent
