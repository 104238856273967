import styled from 'styled-components'
import { Flex } from 'rebass'

const color = `hsla(0, 0%, 50%, 0.2)`

const Footer = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
  border-top: solid 1px ${color};

  button {
    border-radius: 0;
  }
  button:last-child {
    border-left: solid 1px ${color};
  }
`

export default Footer
